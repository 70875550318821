import React from 'react';
import PropTypes from 'prop-types';

function LocalDate({ dateString, hideTime, monthAndYearOnly=false }) {
  if (dateString == null || dateString === '') return <span>  </span>;
  return <span title={dateString}>{ToLocalDate(dateString, hideTime, monthAndYearOnly)}</span>;
}

LocalDate.propTypes = {
  dateString: PropTypes.string,
  hideTime: PropTypes.bool,
  monthAndYearOnly: PropTypes.bool
};

function ToLocalDate(dateString, hideTime, monthAndYearOnly)
{
  if (dateString == null || dateString == '') return '';
  
  if (!dateString.endsWith("Z"))
  {
    dateString += "Z";
  }

  const dateObject = new Date(Date.parse(dateString));

  let options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  };


  if (hideTime)
  {
    options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
  }

  if(monthAndYearOnly){
    options = {
        year: 'numeric',
        month: 'long'
    }
  }

  if(monthAndYearOnly||hideTime){
    return dateObject.toLocaleDateString(undefined, options);
  }
  
  return dateObject.toLocaleTimeString(undefined, options);
}

export default LocalDate;
export {ToLocalDate};